import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import whatsapp from '../images/whatsapp.svg'
const docLink = {
    text: "Documentation",
    url: "https://api.whatsapp.com/send?phone=529994168479&text=https://api.whatsapp.com/send?phone=529994168479&text=Hola,%20quisiera%20mas%20informaci%C3%B3n%20sobre%20sus%20productos%20y%20servicios.",
    color: "#8954A8",
  }

const Footer = () => (
    <footer>
         <a href={`${docLink.url}`} className="link-whats d-inline-block" target="_blank">
            <img src={whatsapp} alt='whatsapp'/>
          </a>
        <div className="container-fluid" style={{maxWidth: "1600px"}}>
        
            
            <div className="row justify-content-center pt-5 pb-5">
                <StaticImage
                    src="../images/logo.svg"
                    alt="Logo-Gradual"
                    layout="fixed"
                    width={180}
                />
            </div>
        </div>
    </footer>
)

export default Footer;