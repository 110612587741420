import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Swal from 'sweetalert2'

class FormContacto extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nombre: "",
            telefono: "",
            email: "",
            mensaje: "",

            verificado: false,
            enviado: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    handleSubmit(ev) {
        
       
        ev.preventDefault();
        let form = document.querySelector(".validate");

        if (form.checkValidity() === false) {
            //Campos invalidos
            ev.preventDefault();
            ev.stopPropagation();
        } else {
            //TODO: Verificar captcha
            //Hacemos submit jeje
            if(this.state.verificado){
             
                // console.log(`${process.env.REACT_APP_API_URL}`);
                this.setState({...this.state, sending: true});


                axios.post(`https://api.gradual.gradual.com.mx/newcontact`, this.state)
                .then(res => {
                    console.log(res.status);
                    if((res.status === 200) && (res.data.success)){
                        // Mostramos mensaje enviado
                        Swal.fire(
                            'Operación exitosa',
                            'Mensaje enviado',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Oops!',
                            'Lo sentimos, algo salió mal',
                            'error'
                        )
                    }
                    this.setState({...this.state, sending: false});
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Oops!',
                        'Lo sentimos, algo salió mal',
                        'error'
                    )
                    this.setState({...this.state, sending: false});
                });
            } else{
                Swal.fire(
                    'Oops...',
                    'Favor de verificar el captcha',
                    'warning'
                )
            }
        }

        form.classList.add('was-validated');
    }

    verifyCallback (response) {
        this.setState({...this.state, verificado: true});
    }

    showButton(){
        if(this.state.sending){
            return <button type="submit" className="btn btn-blanco-amarillo text-uppercase disabled m-0" style={{PointerEvent: "none"}}>Enviando....</button>
        }else{
            return <button className="btn-submit m-0" type="submit" onClick={this.handleSubmit}>Enviar</button>
        }
    }

    render () {
        return (
            <form className="validate" noValidate>
                <div className="mb-3">
                    <input type="text" name="nombre" id="name" required className="form-control" placeholder="Nombre" onChange={(el) => {this.setState({...this.state, nombre: el.target.value})}} />
                </div>
                <div className="mb-3">
                    <input type="tel" name="telefono" id="tel" required className="form-control" placeholder="Télefono" onChange={(el) => {this.setState({...this.state, telefono: el.target.value})}}/>
                </div>
                <div className="mb-3">
                    <input type="email" name="email" id="email" required className="form-control" placeholder="Correo" onChange={(el) => {this.setState({...this.state, email: el.target.value})}} />
                </div>
                <div className="mb-3">
                    <textarea name="mensaje" id="message" rows="5"  required className="form-control" placeholder="Mensaje" onChange={(el) => {this.setState({...this.state, mensaje: el.target.value})}}/>
                </div>
                
               
                
                
                
                <div className="row justify-content-between d-flex">
                    <div className="mb-3 display-button">
                        <ReCAPTCHA
                            sitekey="6Ld3CBEbAAAAAD6Osi5asGzQl82UGPxepTh3RidC"
                            onChange={this.verifyCallback}
                            size="normal"
                            badge="inline"
                        />
                    </div>
                    <div className="display-button button-text">
                        {this.showButton()}
                    </div>
                </div>
               
            </form>
        );
    }
}

export default FormContacto;