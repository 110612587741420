import * as React from "react"

import Menu from "../components/menu_simple"
import Layout from "../components/layout"
import CarouselPage from "../components/slider"
import Seo from "../components/seo"
import FormContacto from '../components/FormContacto';

import "../sass/app/menu_version_1.scss"
import "../sass/pages/home.scss"

import imagetonelada from '../images/Malacate_tonelada.png'

const IndexPage = () => (
  <Layout>
		<Seo title="Home" />
		
		
		<Menu />
	

		<div className="home overflow-hidden">
			<div className="home-slide">
				<div className="container-fluid w14 p-0">
					<CarouselPage/>
				</div>
			</div>
			<div className="container-fluid section-1" >
				<div className="row">
					<div className="col-12 col-lg-3 color-orange">
						<div className="row justify-content-center m-0">
							<div className="col-10">
								<div className="text-center">
									<h2>GRAN VARIEDAD DE EQUIPOS EN EXISTENCIA A TU DISPOSICIÓN.</h2>
								</div>
								<p>Contamos con el respaldo de las mejores marcas fabricantes de maquinaria y equipos para construcción.</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-3 color-grey">
						<div className="row justify-content-center m-0">
							<div className="col-10">
								<div className="text-center">
									<h3>Productos</h3>
								</div>
								<p>Andamios estándar</p>
								<p>Andamios banqueteros</p>
								<p>Puntales metálicos</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-3 color-grey">
						<div className="row justify-content-center m-0">
							<div className="col-10">
								<div className="text-center">
									<h3>Maquinaria ligera</h3>
								</div>
								<p>Revolvedora de cemento de un saco</p>
								<p>Rotomartillo demoledor</p>
								<p>Vibrador de concreto</p>
								<p>Placa vibratoria</p>
								<p>Malacate de 1 tonelada</p>
								<p>Planta eléctrica</p>
							</div>
						</div>
					</div>
			
					<div className="col-12 col-lg-3 color-grey-2">
						<div className="row m-0">
							<div className="col-10">
								<img className='d-block w-100' src={imagetonelada} alt='Malacate-1-tonelada'/>
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-content-center  section-3">
					<div className="col-12 col-lg-4 col-md-5 col-sm-5">
						<h3>Contáctanos</h3>
						<div className="mt-5"></div>
						<p>
							<a className="m-0 p-0" href="tel:9994168479">9994168479</a>
							
						</p>
						<p className="mb-4">
							<a className="m-0 p-0" href="mailto:contacto@gradual.com.mx">contacto@gradual.com.mx</a>
						</p>
						<p>Calle 24 No. 242</p>
						<p>Montes de Amé. C.P. 97115</p>
					</div>

					
					
					<div className="col-12 col-lg-4 col-md-5 col-sm-5">
						<h3>Solicita una cotización</h3>
						<div className="mt-5"></div>
						<FormContacto />
					</div>
				</div>
			</div>
			

		
		</div>

  </Layout>
)

export default IndexPage
