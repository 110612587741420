import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import image from '../images/logo.svg'

const Header = () => (
  <header className="menu">
	  <div className="row m-0">
		  <div className="col-12">
			  <div className="logo-header">
			  	
					<img className='d-block ' src={image} alt='Malacate-1-tonelada'  width={180}/>
			  </div>
		  </div>
	  </div>
		
  </header>
)

export default Header
