import * as React from "react"

import { Carousel } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, Keyboard, EffectFade } from 'swiper';
import { Swiper, SwiperSlide} from 'swiper/react';


import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper-bundle.min.css'

import banner1 from '../images/banner1.jpg';
import banner2 from '../images/banner2.jpg';
import banner3 from '../images/banner3.jpg';
import banner4 from '../images/banner4.jpg';
import banner5 from '../images/banner5.jpg';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay, Keyboard,]);

const CarouselPage = () => (
 
  <Swiper
    spaceBetween={0}
    speed={300}
    autoplay={true}
    navigation
    keyboard
    loop={true}
    pagination={{ clickable: true }}
  >
    <SwiperSlide>
        <div className="bg d-none d-sm-block " style={{backgroundImage: `url(${banner1})`}}> <img src={banner1} alt="Base slide" className="img-fluid" /> </div>
        <div className="bg d-sm-none" style={{backgroundImage: `url(${banner1})`}}> <img src={banner1} alt="Base slide" /> </div>
    </SwiperSlide>
    <SwiperSlide>
        <div className="bg d-none d-sm-block " style={{backgroundImage: `url(${banner2})`}}> <img src={banner2} alt="Base slide" className="img-fluid"/> </div>
        <div className="bg d-sm-none" style={{backgroundImage: `url(${banner2})`}}> <img src={banner2} alt="Base slide" /> </div>
    </SwiperSlide>
    <SwiperSlide>
        <div className="bg d-none d-sm-block " style={{backgroundImage: `url(${banner3})`}}> <img src={banner3} alt="Base slide" className="img-fluid"/> </div>
        <div className="bg d-sm-none" style={{backgroundImage: `url(${banner3})`}}> <img src={banner3} alt="Base slide" /> </div>
    </SwiperSlide>
    <SwiperSlide>
        <div className="bg d-none d-sm-block " style={{backgroundImage: `url(${banner4})`}}> <img src={banner4} alt="Base slide" className="img-fluid"/> </div>
        <div className="bg d-sm-none" style={{backgroundImage: `url(${banner4})`}}> <img src={banner4} alt="Base slide" /> </div>
    </SwiperSlide>
    <SwiperSlide>
        <div className="bg d-none d-sm-block " style={{backgroundImage: `url(${banner5})`}}> <img src={banner5} alt="Base slide"/> </div>
        <div className="bg d-sm-none" style={{backgroundImage: `url(${banner5})`}}> <img src={banner5} alt="Base slide" /> </div>
    </SwiperSlide>
  </Swiper>
)
  
export default CarouselPage